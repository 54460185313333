"use strict";

// this function will create clickable tabs for content
function openPage(pageName, elmnt, color) {
  // set variables. tablinks are the "tabs" themselves. tabcontent is self explanatory    
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  // style to background color of the tab
  tablinks = document.getElementsByClassName("tablink");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].style.backgroundColor = "";
  }

  // style background color of tabcontent - need to creat tab effect
  document.getElementById(pageName).style.display = "block";
  elmnt.style.backgroundColor = color;
}

// Get the element with id="defaultOpen" and click on it
document.getElementById("defaultOpen").click();

// Accordion stuff

/**
* Accordion listener
*/
if ($('.oac__accordion').length > 0) {
  $(".oac__accordion").on("click", ".oac-accordion__button", function () {
    //add active class and open next slide
    $(this).toggleClass("oac-accordion-button__active").next().slideToggle();
  });
}
$(document).ready(function () {
  $('.housing__intro-slider').slick({
    //infinite: true,
    speed: 300,
    autoplay: true,
    useTransform: false,
    arrows: true,
    prevArrow: "<img class='slick-prev' src='../images/slider/arrows/intro-arrow.png'>",
    nextArrow: "<img class='slick-next' src='../images/slider/arrows/intro-arrow.png'>"
  });
});
wow = new WOW({
  boxClass: 'wow',
  // default
  animateClass: 'animated',
  // default
  offset: 0,
  // default
  mobile: false,
  // default
  live: true // default
});

wow.init();